@import url('https://fonts.googleapis.com/css2?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&display=swap');

$background-colour: #000000;
$background-highlight: #444444;

$text-colour: #ffffff;
$text-highlight-colour: rgb(230,128,0);

$header-colour: #222222;

$font-family-sans: "Open Sans", sans-serif;
$font-family-serif: "PT Serif", serif;

$min-width: 400px;

@import "overrides";
@import "header";
@import "spinner";
@import "cubo";
@import "info-box";