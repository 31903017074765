#info-box {
	max-width: 1100px;
	margin: 0 auto;
	padding: 1.5rem;
	position: relative;
	z-index: 10000000;

	.topper {
		text-align: center;
		a {
			text-decoration: none;
		}
	}

	.instructions {
		text-align: center;
		opacity: .6;
		font-style: italic;
		font-family: #{$font-family-serif};
	}
}