html {
	scroll-behavior: smooth;
}
 
body, #root, .App {
	z-index: -10000000;
	position: relative;
	margin: 0;
	font-family: #{$font-family-sans};
	font-size: 1.1rem;
}

body {
	background-color: $background-colour;
	background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2368636f' fill-opacity='0.51' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
	color: $text-colour;
}

.App {
	overflow: hidden;
	min-height: 100vh;
	width: 100%;
}

h1, p {
	font-family: #{$font-family-serif};
}

h3 {
	font-style: italic;
}

a {
	color: $text-colour;

	&:visited {
		color: $text-colour;
	}

	&:hover {
		color: $text-highlight-colour;
	}
}