#spinner {
	$spin-width: 100%;
	$spin-max-width: 700px;
	$spin-height: 500px;

	width: $spin-width; 
	max-width: $spin-max-width; 
	height: $spin-height; 
	pointer-events: none; 
	position: relative;
	margin: 0 auto;

	#box {
		position: absolute;
		height: 200px;
		width: 200px;
		top: calc(50% - 100px);
		left: calc(50% - 100px);
		z-index: 0;
		pointer-events: none; 
	}

	.pause-button {
		position: absolute;
		bottom: 0;
		width: 100%;
		text-align: center;
		span {
			pointer-events: auto; 
			cursor: pointer;
			opacity: .5;
			font-size: 1.3rem;
		}
	}

	.spin-container {
		transform-style: preserve-3d;
		width: $spin-width;
		max-width: $spin-max-width; 
		height: $spin-height;
		position: absolute;
		pointer-events: none;

		.spinnee {
			position: absolute;
			pointer-events: auto; 
			cursor: pointer;
			background-color: transparent; 
			color: $text-colour;
			border: 1px solid $text-colour;
			border-radius: 2px;
			font-size: 1rem;

			&.selected {
				color: $background-colour;
				background-color: $text-colour;
			}

			&:hover {
				border: 1px solid $text-highlight-colour;
			}
		}
	}
}